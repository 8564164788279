import React from 'react';
import { GatsbyImageProps, StaticImage } from 'gatsby-plugin-image';

import { BackgroundTexture } from './background-texture';
import { SideBySide } from './side-by-side';
import { ButtonLink } from './button-link';

interface AboutSectionProps {
  title?: string;
  cta: string;
  ctaPath: string;
  description: React.ReactElement;
  image: React.ReactElement;
}

function AboutSection({
  title,
  cta,
  ctaPath,
  description,
  image,
}: AboutSectionProps): React.ReactElement {
  return (
    <SideBySide background={<BackgroundTexture />}>
      <SideBySide.TwoCols>
        <div className="w-full mx-auto max-w-prose md:pt-16 lg:py-16">
          <div className="max-w-xs mx-auto lg:max-w-prose lg:mx-0">
            <div className="aspect-w-1 aspect-h-1">
              <div className="flex">{image}</div>
            </div>
          </div>
        </div>
      </SideBySide.TwoCols>
      <SideBySide.ThreeCols>
        <div className="relative mx-4 md:mx-auto md:mb-16 lg:my-16">
          {title ? (
            <h2 className="text-4xl font-semibold">
              <span className="block py-1 md:text-center text-custom-gradient lg:text-left">
                {title}
              </span>
            </h2>
          ) : (
            ''
          )}
          <div className="mx-auto mt-5 font-medium prose md:text-center text-gray-500 lg:text-left">
            {description}
          </div>
          <p className="mt-5 md:text-center lg:text-left">
            <ButtonLink aria-label="Read more about Adore" to={ctaPath}>
              {cta}
            </ButtonLink>
          </p>
        </div>
      </SideBySide.ThreeCols>
    </SideBySide>
  );
}

export { AboutSection };
