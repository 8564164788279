import * as React from 'react';

type RadioButtonProps = {
  id: string;
  label: string;
  name: string;
  rest?: unknown[];
  handleGender?: any;
};

const Radio = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ id, label, name, handleGender, ...rest }, ref) => (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          ref={ref}
          id={id}
          name={name}
          value={label}
          type="radio"
          className="w-4 h-4 border-pink text-pink focus:ring-pink"
          onChange={() => handleGender(name)}
          {...rest}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={name} className="block text-sm font-semibold">
          {label}
        </label>
      </div>
    </div>
  )
);

export { Radio };
