import React from 'react';
import { BackgroundTexture } from './background-texture';
import { MultiStepForm } from './multi-step-form';

function ConsultationForm() {
  return (
    <div id="consultation-form" className="relative block mx-auto w-11/12">
      <BackgroundTexture position="right" />
      <div className="md:w-10/12 mx-auto z-10 py-0 md:py-6">
        <div className="px-2 py-12 mt-16 md:my-8 md:py-16 mx-auto max-w-screen-2xl sm:px-6 lg:px-8 relative">
          <div className=" border-4 border-pink rounded-3xl overflow-hidden bg-pink-lightest">
            <div className="xl:flex">
              <div className="py-12 md:py-24 px-8 md:px-12 font-semibold">
                <h2 className="text-2xl md:text-3xl">
                  <span className="block whitespace-nowrap text-custom-gradient">
                    Cosmetic Tattooing <br /> Consultation Form
                  </span>
                </h2>
                <div className="my-6 md:text-lg  text-custom-gradient">
                  Let’s talk about your brows!
                </div>
                <div className="my-8 md:text-lg  text-custom-gradient">
                  Fill out our easy 4 step form and we will get back to you.
                </div>
              </div>
              <div className="pt-8 pb-3 xl:pb-0 px-4 md:pt-14 md:px-12 w-full bg-white">
                {/* consultation form */}
                <MultiStepForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { ConsultationForm };
