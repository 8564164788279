import { Link } from 'gatsby';
import * as React from 'react';

type Anchor = { href: string; to?: never };
type GatsbyLink = { href?: never; to: string };

type ButtonLinkProps = {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rest?: any[];
} & (Anchor | GatsbyLink);

const className =
  'inline-flex px-8 py-2 font-semibold text-white rounded-full bg-gradient-to-r from-pink-darker via-pink-light to-pink-dark';

function ButtonLink({
  children,
  href,
  to,
  ...rest
}: ButtonLinkProps): React.ReactElement {
  if (href) {
    return (
      <a {...rest} href={href} className={className}>
        {children}
      </a>
    );
  }
  return (
    <Link {...rest} to={to as string} className={className}>
      {children}
    </Link>
  );
}

export { ButtonLink };
