import classNames from 'classnames';
import React, { useState } from 'react';
import { CgArrowLongRight } from 'react-icons/cg';
import { Fieldset } from '../form-elements/fieldset';
import { Input } from '../form-elements/input';
import { Legend } from '../form-elements/legend';
import { Radio } from '../form-elements/radio';

const Step1 = ({ register, errors, handleNext, buttonStyle }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const validateInput = (e) => {
    const fullNameInput = document.querySelector(
      '#full_name'
    ) as HTMLInputElement;
    const emailAddressInput = document.querySelector(
      '#email_address'
    ) as HTMLInputElement;
    const phoneNumberInput = document.querySelector(
      '#phone_number'
    ) as HTMLInputElement;
    const gender = document.querySelectorAll(
      '[name="gender"]'
    ) as NodeListOf<Element>;
    const isGenderSelected = Array.prototype.slice
      .call(gender)
      .map((item) => item.checked)
      .includes(true);

    if (
      !isGenderSelected ||
      !fullNameInput.value ||
      !emailAddressInput.value ||
      !phoneNumberInput.value
    ) {
      return setErrorMessage('Please complete all fields');
    }
    setErrorMessage('');
    return handleNext(e);
  };

  return (
    <div>
      <div>
        <h3 className="uppercase font-bold text-xl text-pink-darker">Step 1</h3>
        <h4 className="text-xl font-semibold text-custom-gradient">
          Contact Details
        </h4>
      </div>
      <div className="my-6">
        <div className="grid grid-cols-1 gap-2">
          <Input
            label="Full Name"
            required={true}
            addBorder
            rounded={true}
            {...register('full_name', { required: true })}
            errors={errors}
          />
          <Input
            label="Email"
            addBorder
            rounded={true}
            {...register('email_address', { required: true })}
            errors={errors}
          />
          <Input
            label="Phone Number"
            {...register('phone_number', { required: true })}
            errors={errors}
            rounded={true}
            addBorder
          />
          <Fieldset>
            <div className="flex items-center space-x-8">
              <div>
                <Legend>Gender</Legend>
              </div>
              <div className="flex space-x-2 md:space-x-12 capitalize">
                <Radio
                  id="female"
                  label="female"
                  {...register('gender', {
                    required: true,
                  })}
                />
                <Radio
                  id="male"
                  label="male"
                  {...register('gender', {
                    required: true,
                  })}
                />
              </div>
            </div>
          </Fieldset>
        </div>
        <div className="flex items-center">
          <button
            id="step1"
            onClick={(e) => validateInput(e)}
            className={classNames(buttonStyle, 'flex items-center my-8')}
          >
            Next <CgArrowLongRight className="text-white ml-2" />
          </button>
          <div className="text-xs ml-2 text-pink-darker">{errorMessage}</div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
