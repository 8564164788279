import classNames from 'classnames';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { NetlifyForm } from './form-elements/netlify-form';

import Step1 from './multi-step-form-steps/Step1';
import Step2 from './multi-step-form-steps/Step2';
import Step3 from './multi-step-form-steps/Step3';
import Step4 from './multi-step-form-steps/Step4';

type FormData = {
  full_name: string;
  email_address: string;
  phone_number: string;
  message: string;
  gender: 'Male' | 'Female';
  over_18: 'Yes' | 'No';
  on_medication: 'Yes' | 'No';
  able_to_use_topical_anesthetics: 'Yes' | 'No';
  pregnant_planning_breastfeeding: 'Yes' | 'No';
  have_oily_skin: 'Yes' | 'No';
  had_eyebrows_tattooed: 'Yes' | 'No';
  have_health_concerns: 'Yes' | 'No';
  is_iron_deficient_anemic: 'Yes' | 'No';
  suffer_from_keloid_scarring: 'Yes' | 'No';
  suffer_from_anxietly_depression: 'Yes' | 'No';
  image_files: any;
};

const buttonStyle =
  'inline-flex px-16 py-3 text-sm font-semibold text-white rounded-full bg-gradient-to-r from-pink-darker via-pink-light to-pink-dark undefined';

function MultiStepForm() {
  const [hideStep1, setHideStep1] = useState(false);
  const [hideStep2, setHideStep2] = useState(true);
  const [hideStep3, setHideStep3] = useState(true);
  const [hideStep4, setHideStep4] = useState(true);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const handleNext = (e) => {
    e.preventDefault();
    switch (e.target.id) {
      case 'step1':
        setHideStep1(true);
        setHideStep2(false);
        break;
      case 'step2':
        setHideStep2(true);
        setHideStep3(false);
        break;
      case 'step3':
        setHideStep3(true);
        setHideStep4(false);
        break;
      default:
        null;
        break;
    }
  };

  return (
    <NetlifyForm handleSubmit={handleSubmit} name="consultation_form">
      <div className={classNames(hideStep1 ? 'hidden' : '')}>
        <Step1
          register={register}
          errors={errors}
          handleNext={handleNext}
          buttonStyle={buttonStyle}
        />
      </div>
      <div className={classNames(hideStep2 ? 'hidden' : '')}>
        <Step2
          register={register}
          errors={errors}
          handleNext={handleNext}
          buttonStyle={buttonStyle}
        />
      </div>
      <div className={classNames(hideStep3 ? 'hidden' : '')}>
        <Step3
          register={register}
          errors={errors}
          handleNext={handleNext}
          buttonStyle={buttonStyle}
        />
      </div>
      <div className={classNames(hideStep4 ? 'hidden' : '')}>
        <Step4 register={register} errors={errors} buttonStyle={buttonStyle} />
      </div>
    </NetlifyForm>
  );
}

export { MultiStepForm };
