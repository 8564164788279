import * as React from 'react';

interface FieldsetProps {
  children: React.ReactNode;
  isNested?: boolean;
  rest?: unknown[];
}

function Fieldset({
  children,
  isNested,
  rest,
}: FieldsetProps): React.ReactElement {
  return (
    <fieldset className="mt-6" {...rest}>
      <div className="grid gap-4">{children}</div>
    </fieldset>
  );
}

export { Fieldset };
