import * as React from 'react';
import { DeepMap, FieldError } from 'react-hook-form';

import { ErrorMessage } from './error-message';

type InputImageProps = {
  handleImageUpload: any;
  autoComplete?: string;
  errors: DeepMap<Record<string, unknown>, FieldError>;
  label: string;
  name: string;
  required?: boolean;
} & (
  | { description: string; descriptionId: string }
  | { description?: never; descriptionId?: never }
);

const InputImage = React.forwardRef<HTMLInputElement, InputImageProps>(
  (
    {
      handleImageUpload,
      autoComplete,
      description,
      descriptionId,
      errors,
      label,
      name,
      required,
      ...rest
    },
    ref
  ) => {
    const hasErrors = Boolean(errors?.[name]);
    return (
      <div className="mt-3">
        <label
          htmlFor={name}
          className="block text-md font-semibold tracking-wider"
        >
          <span>{label}</span>
        </label>
        {description ? (
          <span id={descriptionId} className="text-sm">
            {description}
          </span>
        ) : null}
        <div className="w-full mt-3">
          {/* <button
            type="button"
            className="relative inline-flex px-10 py-2 text-sm font-semibold border border-solid rounded-full cursor-pointer border-gray"
          >
            Add file*/}
          <input
            onClick={(e) => handleImageUpload(e)}
            aria-describedby={description ? descriptionId : undefined}
            aria-invalid={hasErrors}
            ref={ref}
            id={name}
            name={name}
            required={required}
            type="file"
            autoComplete={autoComplete}
            multiple
            className="block w-full px-0 py-4 border-transparent outline-none focus:ring-blue-500 focus:ring-opacity-50 focus:border-blue-500 focus:border-opacity-50 focus:outline-none"
            accept=".jpg, .jpeg, .png"
            {...rest}
          />
          {/* </button> */}
        </div>
        <ErrorMessage errors={errors} name={name} label={label} />
      </div>
    );
  }
);

export { InputImage };
