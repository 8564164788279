import * as React from 'react';

type SideBySideProps = {
  children: React.ReactNode;
  background?: React.ReactNode;
};

function SideBySide({
  children,
  background,
}: SideBySideProps): React.ReactElement {
  return (
    <div className="relative">
      {background}
      <div className="relative w-full px-4 py-12 mx-auto overflow-hidden md:py-0 max-w-screen-2xl sm:px-6 lg:px-8">
        <div className="grid gap-8 lg:grid-cols-5">{children}</div>
      </div>
    </div>
  );
}

type TwoColsProps = {
  children: React.ReactNode;
  justifyContent?: 'start' | 'center' | 'end';
};

function TwoCols({
  children,
  justifyContent = 'center',
}: TwoColsProps): React.ReactElement {
  return (
    <div
      className={`relative flex flex-col justify-${justifyContent}  lg:col-span-2`}
    >
      {children}
    </div>
  );
}

type ThreeColsProps = {
  children: React.ReactNode;
  justifyContent?: 'start' | 'center' | 'end';
};

function ThreeCols({
  children,
  justifyContent = 'center',
}: ThreeColsProps): React.ReactElement {
  return (
    <div
      className={`relative flex flex-col justify-${justifyContent} order-last lg:col-span-3 lg:order-none`}
    >
      {children}
    </div>
  );
}

SideBySide.TwoCols = TwoCols;
SideBySide.ThreeCols = ThreeCols;

export { SideBySide };
