import classNames from 'classnames';
import React, { useState } from 'react';
import { CgArrowLongRight } from 'react-icons/cg';
import { Fieldset } from '../form-elements/fieldset';
import { Input } from '../form-elements/input';
import { Legend } from '../form-elements/legend';
import { Radio } from '../form-elements/radio';

const Step2 = ({ register, errors, handleNext, buttonStyle }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const validateInput = (e) => {
    const over_18 = document.querySelectorAll(
      '[name="over_18"]'
    ) as NodeListOf<Element>;
    const on_medication = document.querySelectorAll(
      '[name="on_medication"]'
    ) as NodeListOf<Element>;
    const able_to_use_topical_anesthetics = document.querySelectorAll(
      '[name="able_to_use_topical_anesthetics"]'
    ) as NodeListOf<Element>;
    const pregnant_planning_breastfeeding = document.querySelectorAll(
      '[name="pregnant_planning_breastfeeding"]'
    ) as NodeListOf<Element>;
    const have_oily_skin = document.querySelectorAll(
      '[name="have_oily_skin"]'
    ) as NodeListOf<Element>;

    const isOver18Selected = Array.prototype.slice
      .call(over_18)
      .map((item) => item.checked)
      .includes(true);
    const isOnMedicationSelected = Array.prototype.slice
      .call(on_medication)
      .map((item) => item.checked)
      .includes(true);
    const isAbleToUseTopicalAnestheticsSelected = Array.prototype.slice
      .call(able_to_use_topical_anesthetics)
      .map((item) => item.checked)
      .includes(true);
    const isPregnantPlanningBreastfeedingSelected = Array.prototype.slice
      .call(pregnant_planning_breastfeeding)
      .map((item) => item.checked)
      .includes(true);
    const isOilySelected = Array.prototype.slice
      .call(have_oily_skin)
      .map((item) => item.checked)
      .includes(true);

    if (
      !isOver18Selected ||
      !isOnMedicationSelected ||
      !isAbleToUseTopicalAnestheticsSelected ||
      !isPregnantPlanningBreastfeedingSelected ||
      !isOilySelected
    ) {
      return setErrorMessage('Please complete all fields');
    }
    setErrorMessage('');
    return handleNext(e);
  };

  return (
    <div>
      <div>
        <h3 className="uppercase font-bold text-xl text-pink-darker">Step 2</h3>
        <h4 className="text-xl font-semibold text-custom-gradient">
          Yes or No
        </h4>
      </div>
      <div className="my-4">
        <div className="grid grid-cols-1 gap-2">
          <Fieldset>
            <div className="">
              <div>
                <Legend>Are you over 18?</Legend>
              </div>
              <div className="flex space-x-12 capitalize mt-2">
                <Radio
                  id="Yes_over_18"
                  label="Yes"
                  {...register('over_18', {
                    required: true,
                  })}
                />
                <Radio
                  id="No_over_18"
                  label="No"
                  {...register('over_18', {
                    required: true,
                  })}
                />
              </div>
            </div>
          </Fieldset>
        </div>
        {/* medication */}
        <div className="grid grid-cols-1 gap-2">
          <Fieldset>
            <div className="">
              <div>
                <Legend>Are you currently using any medication?</Legend>
              </div>
              <div className="flex space-x-12 capitalize mt-2">
                <Radio
                  id="Yes_on_medication"
                  label="Yes"
                  {...register('on_medication', {
                    required: true,
                  })}
                />
                <Radio
                  id="No_on_medication"
                  label="No"
                  {...register('on_medication', {
                    required: true,
                  })}
                />
              </div>
            </div>
          </Fieldset>
        </div>
        <div className="my-3">
          <Input
            label="Please list what medication you are currently using"
            {...register('medication', { required: false })}
            errors={errors}
            addBorder
            rounded={true}
          />
        </div>
        {/* anesthetics */}
        <div className="grid grid-cols-1 gap-2">
          <Fieldset>
            <div className="">
              <div>
                <Legend>
                  Are you able to use topical anesthetics? (lignocaine,
                  tetracaine, prilocaine, epinephrine)
                </Legend>
              </div>
              <div className="flex space-x-12 capitalize mt-2">
                <Radio
                  id="Yes_able_to_use_topical_anesthetics"
                  label="Yes"
                  {...register('able_to_use_topical_anesthetics', {
                    required: true,
                  })}
                />
                <Radio
                  id="No_able_to_use_topical_anesthetics"
                  label="No"
                  {...register('able_to_use_topical_anesthetics', {
                    required: true,
                  })}
                />
              </div>
            </div>
          </Fieldset>
        </div>
        {/* pregnant */}
        <div className="grid grid-cols-1 gap-2">
          <Fieldset>
            <div className="">
              <div>
                <Legend>
                  Are you pregnant, planning pregnancy or breastfeeding?
                </Legend>
              </div>
              <div className="flex space-x-12 capitalize mt-2">
                <Radio
                  id="Yes_pregnant_planning_breastfeeding"
                  label="Yes"
                  {...register('pregnant_planning_breastfeeding', {
                    required: true,
                  })}
                />
                <Radio
                  id="No_pregnant_planning_breastfeeding"
                  label="No"
                  {...register('pregnant_planning_breastfeeding', {
                    required: true,
                  })}
                />
              </div>
            </div>
          </Fieldset>
        </div>
        {/* oily skin */}
        <div className="grid grid-cols-1 gap-2">
          <Fieldset>
            <div className="">
              <div>
                <Legend>Do you have oily skin?</Legend>
              </div>
              <div className="flex space-x-12 capitalize mt-2">
                <Radio
                  id="Yes_have_oily_skin"
                  label="Yes"
                  {...register('have_oily_skin', {
                    required: true,
                  })}
                />
                <Radio
                  id="No_have_oily_skin"
                  label="No"
                  {...register('have_oily_skin', {
                    required: true,
                  })}
                />
              </div>
            </div>
          </Fieldset>
        </div>
        <div className="flex items-center">
          <button
            id="step2"
            onClick={(e) => validateInput(e)}
            className={classNames(buttonStyle, 'flex items-center my-8')}
          >
            Next <CgArrowLongRight className="text-white ml-2" />
          </button>
          <div className="text-xs ml-2 text-pink-darker">{errorMessage}</div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
