import classNames from 'classnames';
import React, { useState } from 'react';
import { InputImage } from '../form-elements/input-image';
import { Textarea } from '../form-elements/textarea';

const Step4 = ({ register, errors, buttonStyle }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [hasImageFiles, setHasImageFiles] = useState(false);
  const [imageFiles, setImageFiles] = useState('');

  const handleImageUpload = (e) => {
    setTimeout(() => {
      const image = Array.prototype.slice.call(e.target.files);
      if (image.length !== 0) {
        setHasImageFiles(true);
        setImageFiles(image.map((item) => item.name).join('\r\n'));
      }
    }, 5000);
  };

  const handleSubmit = (e) => {
    const messageInput = document.querySelector('#message') as HTMLInputElement;
    if (!hasImageFiles || !messageInput) {
      e.preventDefault();
      return setErrorMessage('Please complete all fields');
    }
    setErrorMessage('');
    return;
  };

  return (
    <div>
      <div>
        <h3 className="uppercase font-bold text-xl text-pink-darker">Step 4</h3>
        <h4 className="text-xl font-semibold text-custom-gradient">
          Upload Photos
        </h4>
      </div>
      <div className="my-6">
        <div className="grid grid-cols-1 gap-2">
          <div className="font-semibold">
            Upload some clear photos of your bare brows (natural lighting, no
            filters) <br /> 10mb MAX Limit *
          </div>
          <div className="mt-6 flex space-x-6">
            {/* file attachment */}
            <label
              className={classNames(
                hasImageFiles
                  ? 'bg-gray-lightest text-white'
                  : 'bg-white border-gray',
                'px-6 md:px-12 border rounded-3xl  cursor-pointer hover:bg-pink hover:text-white ease-linear transition-all duration-150'
              )}
            >
              <div>
                <InputImage
                  handleImageUpload={handleImageUpload}
                  className="hidden"
                  label="Add File(s)"
                  {...register('image_files', { required: false })}
                  errors={errors}
                />
              </div>
            </label>
            <div>
              JPG, PNG files <br /> accepted
            </div>
          </div>
          <div className="text-xs mt-3 mx-6 md:mx-12 text-pink-darker whitespace-pre">
            {hasImageFiles ? imageFiles : ''}
          </div>
        </div>
        <div className="mt-16">
          <div className="font-semibold">Additional Information</div>
          <Textarea
            addBorder
            label="Message"
            placeholder="(Describe any information not included on this form and what you would like to achieve with Cosmetic Tattooing) *"
            {...register('message', { required: true })}
            errors={errors}
            rounded={true}
          />
        </div>
        <div className="flex items-center">
          <button
            onClick={(e) => handleSubmit(e)}
            className={classNames(buttonStyle, 'flex items-center my-8')}
          >
            Submit
          </button>
          <div className="text-xs ml-2 text-pink-darker">{errorMessage}</div>
        </div>
      </div>
    </div>
  );
};

export default Step4;
