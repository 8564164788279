import classNames from 'classnames';
import React, { useState } from 'react';
import { CgArrowLongRight } from 'react-icons/cg';
import { Fieldset } from '../form-elements/fieldset';
import { Legend } from '../form-elements/legend';
import { Radio } from '../form-elements/radio';

const Step3 = ({ register, errors, handleNext, buttonStyle }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const validateInput = (e) => {
    const had_eyebrows_tattooed = document.querySelectorAll(
      '[name="had_eyebrows_tattooed"]'
    ) as NodeListOf<Element>;
    const have_health_concerns = document.querySelectorAll(
      '[name="have_health_concerns"]'
    ) as NodeListOf<Element>;
    const is_iron_deficient_anemic = document.querySelectorAll(
      '[name="is_iron_deficient_anemic"]'
    ) as NodeListOf<Element>;
    const suffer_from_keloid_scarring = document.querySelectorAll(
      '[name="suffer_from_keloid_scarring"]'
    ) as NodeListOf<Element>;
    const suffer_from_anxiety_depression = document.querySelectorAll(
      '[name="suffer_from_anxiety_depression"]'
    ) as NodeListOf<Element>;

    const had_eyebrows_tattooed_selected = Array.prototype.slice
      .call(had_eyebrows_tattooed)
      .map((item) => item.checked)
      .includes(true);
    const have_health_concerns_selected = Array.prototype.slice
      .call(have_health_concerns)
      .map((item) => item.checked)
      .includes(true);
    const is_iron_deficient_anemic_selected = Array.prototype.slice
      .call(is_iron_deficient_anemic)
      .map((item) => item.checked)
      .includes(true);
    const suffer_from_keloid_scarring_selected = Array.prototype.slice
      .call(suffer_from_keloid_scarring)
      .map((item) => item.checked)
      .includes(true);
    const suffer_from_anxiety_depression_selected = Array.prototype.slice
      .call(suffer_from_anxiety_depression)
      .map((item) => item.checked)
      .includes(true);

    if (
      !had_eyebrows_tattooed_selected ||
      !have_health_concerns_selected ||
      !is_iron_deficient_anemic_selected ||
      !suffer_from_keloid_scarring_selected ||
      !suffer_from_anxiety_depression_selected
    ) {
      return setErrorMessage('Please complete all fields');
    }
    setErrorMessage('');
    return handleNext(e);
  };

  return (
    <div>
      <div>
        <h3 className="uppercase font-bold text-xl text-pink-darker">Step 3</h3>
        <h4 className="text-xl font-semibold text-custom-gradient">
          Yes or No
        </h4>
      </div>
      <div className="my-6">
        <div className="grid grid-cols-1 gap-2">
          {/* had eyebrow tattoo before */}
          <div className="grid grid-cols-1 gap-2">
            <Fieldset>
              <div className="">
                <div>
                  <Legend>Have you had your eyebrows tattooed before?</Legend>
                </div>
                <div className="flex space-x-12 capitalize mt-2">
                  <Radio
                    id="Yes_had_eyebrows_tattooed"
                    label="Yes"
                    {...register('had_eyebrows_tattooed', {
                      required: true,
                    })}
                  />
                  <Radio
                    id="No_had_eyebrows_tattooed"
                    label="No"
                    {...register('had_eyebrows_tattooed', {
                      required: true,
                    })}
                  />
                </div>
              </div>
            </Fieldset>
          </div>
          {/* health concerns */}
          <div className="grid grid-cols-1 gap-2">
            <Fieldset>
              <div className="">
                <div>
                  <Legend>Do you have any health concerns?</Legend>
                </div>
                <div className="flex space-x-12 capitalize mt-2">
                  <Radio
                    id="Yes_have_health_concerns"
                    label="Yes"
                    {...register('have_health_concerns', {
                      required: true,
                    })}
                  />
                  <Radio
                    id="No_have_health_concerns"
                    label="No"
                    {...register('have_health_concerns', {
                      required: true,
                    })}
                  />
                </div>
              </div>
            </Fieldset>
          </div>
          {/* anemic */}
          <div className="grid grid-cols-1 gap-2">
            <Fieldset>
              <div className="">
                <div>
                  <Legend>Are you iron deficient or anemic?</Legend>
                </div>
                <div className="flex space-x-12 capitalize mt-2">
                  <Radio
                    id="Yes_is_iron_deficient_anemic"
                    label="Yes"
                    {...register('is_iron_deficient_anemic', {
                      required: true,
                    })}
                  />
                  <Radio
                    id="No_is_iron_deficient_anemic"
                    label="No"
                    {...register('is_iron_deficient_anemic', {
                      required: true,
                    })}
                  />
                </div>
              </div>
            </Fieldset>
          </div>
          {/* keloid scarring */}
          <div className="grid grid-cols-1 gap-2">
            <Fieldset>
              <div className="">
                <div>
                  <Legend>Do you suffer from keloid scarring?</Legend>
                </div>
                <div className="flex space-x-12 capitalize mt-2">
                  <Radio
                    id="Yes_suffer_from_keloid_scarring"
                    label="Yes"
                    {...register('suffer_from_keloid_scarring', {
                      required: true,
                    })}
                  />
                  <Radio
                    id="No_suffer_from_keloid_scarring"
                    label="No"
                    {...register('suffer_from_keloid_scarring', {
                      required: true,
                    })}
                  />
                </div>
              </div>
            </Fieldset>
          </div>
          {/* anxiety/depression */}
          <div className="grid grid-cols-1 gap-2">
            <Fieldset>
              <div className="">
                <div>
                  <Legend>Do you suffer from anxiety/depression?</Legend>
                </div>
                <div className="flex space-x-12 capitalize mt-2">
                  <Radio
                    id="Yes_suffer_from_anxiety_depression"
                    label="Yes"
                    {...register('suffer_from_anxiety_depression', {
                      required: true,
                    })}
                  />
                  <Radio
                    id="No_suffer_from_anxiety_depression"
                    label="No"
                    {...register('suffer_from_anxiety_depression', {
                      required: true,
                    })}
                  />
                </div>
              </div>
            </Fieldset>
          </div>
        </div>
        <div className="flex items-center">
          <button
            id="step3"
            onClick={(e) => validateInput(e)}
            className={classNames(buttonStyle, 'flex items-center my-8')}
          >
            Next <CgArrowLongRight className="text-white ml-2" />
          </button>
          <div className="text-xs ml-2 text-pink-darker">{errorMessage}</div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
